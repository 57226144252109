export default {
  name: 'Vantagens',
  meta () {
    return {
      title: 'Conveniência na hora da compra e venda - NetCarros',
      description: { name: 'description', content: 'Oferecemos um pacote de vantagens: Garantia Total de 100 dias, 1 ano de motor e câmbio, 10 dias de Test Drive, Revisão de + 250 itens. Acesse agora e confira!' },
      property: { 'name': 'og:image', content: 'https://www.netcarros.com.br/seo/netcarros-vantagens.jpg' },
      link: { material: { rel: 'canonical', href: 'https://www.netcarros.com.br' + this.$route.fullPath } },
      meta: [
        { 'property': 'og:title', 'content': 'Conveniência na hora da compra e venda - NetCarros' },
        { 'name': 'description', 'content': 'Oferecemos um pacote de vantagens: Garantia Total de 100 dias, 1 ano de motor e câmbio, 10 dias de Test Drive, Revisão de + 250 itens. Acesse agora e confira!' },
        { 'property': 'revisit-after', 'content': '5 days' },
        { 'property': 'og:image', 'content': 'https://www.netcarros.com.br/seo/netcarros-vantagens.jpg' },
        { 'property': 'og:image:secure_url', 'content': 'https://www.netcarros.com.br/seo/netcarros-vantagens.jpg' },
        { 'property': 'og:url', 'content': 'https://www.netcarros.com.br' + this.$route.fullPath },
        { 'property': 'og:site_name', 'content': 'NetCarros' },
        { 'property': 'fb:admins', 'content': '', 'vmid': 'fb:admins' },
        { 'property': 'og:locale', 'content': 'pt_BR' }
      ]
    }
  },
  data () {
    return {
      ativo: 0,
      ativo2: 0,
      vantagens: [
        {
          titulo: 'Do seu jeito',
          descricao:
            'Você escolhe, faça todo o processo on-line ou com ajuda de nossos consultores (Telefone ou Whatsapp).',
          icone: 'app:vantagem_1'
        },
        {
          titulo: '10 dias de Test Drive',
          descricao:
            'Caso queira devolver o carro, basta agendar a devolução, sem custo nenhum',
          icone: 'app:vantagem_2'
        },
        {
          titulo: 'Qualidade Netcarros',
          descricao:
            'Todos os nossos carros passam por revisão completa, de laudo cautelar a mecânica, da pintura aos pneus.',
          icone: 'app:vantagem_3'
        },
        {
          titulo: 'Na comodidade da sua casa',
          descricao:
            'Tudo foi pensado para uma compra ou venda sem stress. Faça tudo da sua casa!',
          icone: 'app:vantagem_4'
        }
      ],
      vantagens2: [
        {
          titulo: 'Seu sonho, nossa satisfação',
          descricao:
            'São mais de mil oficinas credenciadas que cuidam dos nossos carros antes e depois da venda.',
          icone: 'app:vantagem_1_2'
        },
        {
          titulo: 'São 365 dias de garantia',
          descricao:
            'Nossos carros são revisados com mais de 250 itens através da nossa Inspeção 360.',
          icone: 'app:vantagem_2_2'
        },
        {
          titulo: 'Conforto do início ao fim',
          descricao:
            'Estaremos aqui em cada momento, por isso, criamos e-mails informativos e alertas para te ajudar no cuidado com o seu novo carro.',
          icone: 'app:vantagem_3_2'
        },
        {
          titulo: 'Aqui é só surpresa boa',
          descricao:
            'Te entregamos um relatório completo do carro, um verdadeiro mapeamento do veículo.',
          icone: 'app:vantagem_4_2'
        }
      ]
    }
  }
}
